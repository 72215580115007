import React, { useContext, useEffect, useState } from "react";
import KMLogo from "../../Images/KM logo@2x.png";
import config from "../../config";
import EndPoints from "../../Constants/EndPoints";
import { LoginContext } from "../../Contexts/LoginContext";
import { POST } from "../../Services/api_services";
import cancellicon from '../../Images/DrawerBackButton.svg';
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import Loader from "../../Components/Loader";
import RouteSegments from "../../Routes/RouteSegment";
import Constants, { loginFrom, EventName, EventKeys } from "../../Constants/constants";
import { useTranslation } from "react-i18next";

const ProfileHobbies = () => {

  const { loginDetail, logout } = useContext(LoginContext);

  const { t: trans } = useTranslation();

  useEffect(() => {
    // Scroll to the top of the page when this component is rendered
    window.scrollTo(0, 0);
  }, []);

  const [hobbiesData, setHobbiesData] = useState([]);
  const [selectedHobbies, setSelectedHobbies] = useState([]);
  const [selectedMusic, setSelectedMusic] = useState([]);
  const [selectedSprots, setSelectedSports] = useState([]);
  const [selectedDirectors, setSelectedDirectors] = useState([]);
  const [selectedBooks, setSelectedBooks] = useState([]);
  const [selectedMovies, setSelectedMovies] = useState([]);
  const [selectedMovieDirectors, setSelectedMovieDirectors] = useState([]);
  const [isLoading, setIsLoading] = useState(false)
  const [onSubmitPopup, setOnSubmitPopup] = useState(false)
  const [favouriteCuisine, setFavouriteCuisine] = useState([])
  const [languagesYouKnow, setLanguagesYouKnow] = useState([])
  const [languagesWish, setLanguagesWish] = useState([])

  const [isDisabled, setIsDisabled] = useState(true)
  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const navigate = useNavigate()
  useEffect(() => {
    apiCallProfileHobbies();
  }, []);

  const apiCallProfileHobbies = async () => {
    setIsLoading(true)
    try {
      const request = {
        email: loginDetail()[0],
        userId: loginDetail()[1],
      };

      const { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.getprofilehobbiesUrl()}`,
        request
      );

      if (statusCode === 200) {

        setHobbiesData(data?.data);
      } else if (statusCode === 401) {
        logout();
      }
    } catch (error) {
      console.error("Error fetching hobbies data:", error);
    }
    setIsLoading(false)
  };

  const handleHobbyButtonClick = (hobbyId) => {
    const isSelected = selectedHobbies.includes(hobbyId);
    const updatedSelection = isSelected
      ? selectedHobbies.filter((id) => id !== hobbyId)
      : [...selectedHobbies, hobbyId];

    setSelectedHobbies(updatedSelection);
  };

  const handleMusicGenreButtonClick = (genreId) => {
    const isSelected = selectedMusic.includes(genreId);
    const updatedSelection = isSelected
      ? selectedMusic.filter((id) => id !== genreId)
      : [...selectedMusic, genreId];

    setSelectedMusic(updatedSelection);
  };

  const handleMusicSprotsButtonClick = (genrelId) => {
    const isSelected = selectedSprots.includes(genrelId);
    const updatedSelection = isSelected
      ? selectedSprots.filter((id) => id !== genrelId)
      : [...selectedSprots, genrelId];

    setSelectedSports(updatedSelection);
  };

  const handleMusicDirectorsButtonClick = (genrelId) => {
    const isSelected = selectedDirectors.includes(genrelId);
    const updatedSelection = isSelected
      ? selectedDirectors.filter((id) => id !== genrelId)
      : [...selectedDirectors, genrelId];

    setSelectedDirectors(updatedSelection);
  };

  const handleMusicBooksButtonClick = (genrelId) => {
    const isSelected = selectedBooks.includes(genrelId);
    const updatedSelection = isSelected
      ? selectedBooks.filter((id) => id !== genrelId)
      : [...selectedBooks, genrelId];

    setSelectedBooks(updatedSelection);
  };
  const handleMusicMoviesButtonClick = (genrelId) => {
    const isSelected = selectedMovies.includes(genrelId);
    const updatedSelection = isSelected
      ? selectedMovies.filter((id) => id !== genrelId)
      : [...selectedMovies, genrelId];

    setSelectedMovies(updatedSelection);
  };
  const handleMusicMovieDirectorsButtonClick = (genrelId) => {
    const isSelected = selectedMovieDirectors.includes(genrelId);
    const updatedSelection = isSelected
      ? selectedMovieDirectors.filter((id) => id !== genrelId)
      : [...selectedMovieDirectors, genrelId];

    setSelectedMovieDirectors(updatedSelection);
  };

  const handleFavouriteCuisineButtonClick = (genrelId) => {
    const isSelected = favouriteCuisine.includes(genrelId);
    const updatedSelection = isSelected
      ? favouriteCuisine.filter((id) => id !== genrelId)
      : [...favouriteCuisine, genrelId];

    setFavouriteCuisine(updatedSelection);
  };

  const handleLanguagesYouKnowButtonClick = (genrelId) => {
    const isSelected = languagesYouKnow.includes(genrelId);
    const updatedSelection = isSelected
      ? languagesYouKnow.filter((id) => id !== genrelId)
      : [...languagesYouKnow, genrelId];

    setLanguagesYouKnow(updatedSelection);
  };

  const handlelanguagewishButtonClick = (genrelId) => {
    const isSelected = languagesWish.includes(genrelId);
    const updatedSelection = isSelected
      ? languagesWish.filter((id) => id !== genrelId)
      : [...languagesWish, genrelId];

    setLanguagesWish(updatedSelection);
  };

  const handleSubmit = async () => {
    try {
      const request = {
        hobbiesInterest: selectedHobbies,
        musicListen: selectedMusic,
        musicDirectors: selectedDirectors,
        booksRead: selectedBooks,
        moviesWatch: selectedMovies,
        movieDirectors: selectedMovieDirectors,
        sportsFitnessActivitiesGames: selectedSprots,
        favouriteCuisine: favouriteCuisine,
        languagesKnow: languagesYouKnow,
        languagesWishToKnow: languagesWish

      };

      const { statusCode, data } = await POST(
        `${config.api.API_URL}${EndPoints.getprofilehobbiesSaveUrl()}`,
        request
      );


      if (statusCode === 200) {
        if (data.data.status === "SUCCESS") {
          setIsLoading(true)

          if (isCommunitySite) {
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
            navigate(RouteSegments.MATCH_GROUP)
          } else {
            localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.PAYMENT_PAGE)
            sessionStorage.setItem(Constants.loginLocalStorageKeys.paymentPage,"true")
            navigate(RouteSegments.PAYMENT_PAGE, {
              replace: true,
            });
          }

        }


      } else if (statusCode === 401) {
        logout();
      }
    } catch (error) {
      console.error("Error fetching hobbies data:", error);
    }
  };

  useEffect(() => {

    if (selectedHobbies.length > 0 || selectedMusic.length > 0 || selectedSprots.length > 0 || selectedDirectors.length > 0 || selectedBooks.length > 0 || languagesYouKnow.length > 0 || languagesWish.length > 0 || favouriteCuisine.length > 0 || selectedMovieDirectors.length > 0 || selectedMovies.length > 0) {
      setIsDisabled(false); // Enable submit button
    } else {
      setIsDisabled(true); // Disable submit button
    }
  }, [selectedHobbies, selectedMusic, selectedSprots, selectedDirectors, selectedBooks, languagesYouKnow, languagesWish, favouriteCuisine, selectedMovieDirectors, selectedMovies]);

  // const handleSkipClick = () => {
  //   if (familysRef.current) {
  //     familysRef.current.scrollIntoView({ behavior: "smooth" });
  //   }
  // };

  useEffect(() => {
    if (hobbiesData) {
      // hobbies
      const initialSelectedIds = hobbiesData?.hobbiesAndInterest
        ?.filter((director) => director.selected === 'Y')
        .map((director) => director.id);
      setSelectedHobbies(initialSelectedIds || []);

      // Music
      const initialSelectedMusic = hobbiesData?.musicListen?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedMusic(initialSelectedMusic || []);

      // Sports
      const initialSelectedSports = hobbiesData?.SportsAndFitnessActivitiesAndGames?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedSports(initialSelectedSports || []);

      // Directors
      const initialSelectedDirectors = hobbiesData?.favoriteMusicDirectors?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedDirectors(initialSelectedDirectors || []);

      // Books
      const initialSelectedBooks = hobbiesData?.TypeofBooksYouRead?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedBooks(initialSelectedBooks || []);

      // Movies
      const initialSelectedMovies = hobbiesData?.TypeOfMoviesYouWatch?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedMovies(initialSelectedMovies || []);

      // Movie Directors
      const initialSelectedMovieDirectors = hobbiesData?.FavoriteMovieDirectors?.filter(item => item.selected === 'Y').map(item => item.id);
      setSelectedMovieDirectors(initialSelectedMovieDirectors || []);

      // Favourite Cuisine
      const initialFavouriteCuisine = hobbiesData?.FavouriteCuisine?.filter(item => item.selected === 'Y').map(item => item.id);
      setFavouriteCuisine(initialFavouriteCuisine || []);

      // Languages You Know
      const initialLanguagesYouKnow = hobbiesData?.languagesYouKnow?.filter(item => item.selected === 'Y').map(item => item.id);
      setLanguagesYouKnow(initialLanguagesYouKnow || []);

      // Languages Wish
      const initialLanguagesWish = hobbiesData?.languagesWish?.filter(item => item.selected === 'Y').map(item => item.id);
      setLanguagesWish(initialLanguagesWish || []);
    
    }
  }, [hobbiesData]);

  return (
    <div>
      <div className="bg-[#F2F1EF]">
        <div className="bg-white sticky top-0 z-10 flex shadow-[0_21px_11px_-15px_#BE363629]">
          <a
            href=""
            className="flex items-center  py-3 "
          >
            <img
              src={KMLogo}
              className="h-12 md:h-[4rem] mx-5 pl-[2.5rem] md:pl-[3.8rem] lg:h-[3.5rem]"
              alt=""
            />
          </a>
        </div>
        <div className=" mx-[1.75rem] md:mx-[5.75rem] mt-[3rem] mb-5 shadow-[-10px_-10px_30px_4px_rgba(0,0,0,0.1),_1px_5px_13px_-1px_rgba(45,78,255,0.15)]">
          <div className="p-[1.25rem] md:p-[2rem] bg-[#FFFFFF]">
            <div className="">
              <p className=" text-[#D63838] text-[25px] p-5 hidden md:flex lg:flex font-semibold font-segeo">
              {trans('add_your_hobbies__interests.add_your_hobbies__interests')}
              </p>
              <p className=" text-block text-[25px] p-5 block md:hidden lg:hidden font-semibold font-segeo">
                Add Your
                <p className="text-[#D63838] font-bold ">{trans('view_profile.hobbies__interests')}</p>
              </p>

              {isLoading ? <Loader className="loaderTransperancy" loaderClassName="loaderTransperancySpin" /> : ""}
              <div>


                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('view_profile.hobbies__interests')}</p>
                  <div className="flex flex-wrap  lg:gap-5 gap-4">
                    {hobbiesData?.hobbiesAndInterest?.map((director) => (

                      <button
                        key={director.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedHobbies.includes(director.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        // style={{width:"max-content"}}
                        onClick={() => handleHobbyButtonClick(director.id)}
                      >
                        {director.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('add_your_hobbies__interests.music_genres_you_listen_to')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.musicListen?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedMusic.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicGenreButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('view_profile.favorite_music_directors')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.favoriteMusicDirectors?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedDirectors.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicDirectorsButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('add_your_hobbies__interests.books_you_read')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.TypeofBooksYouRead?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedBooks.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicBooksButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('add_your_hobbies__interests.movies_you_watch')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.TypeOfMoviesYouWatch?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedMovies.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicMoviesButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('view_profile.favorite_movie_directors')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.FavoriteMovieDirectors?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedMovieDirectors.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicMovieDirectorsButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('view_profile.sports_fitness_activities_game')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.SportsAndFitnessActivitiesAndGames?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${selectedSprots.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleMusicSprotsButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>

                <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">{trans('view_profile.favourite_cuisine')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.FavouriteCuisine?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${favouriteCuisine.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleFavouriteCuisineButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>

                {hobbiesData?.languagesThatIwishToKnow ? <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo">Languages That I wish To Know</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.languagesThatIwishToKnow?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${languagesWish.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handlelanguagewishButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div> : ""}

                {hobbiesData?.languagesYouKnow ? <div className="p-6">
                  <p className="text-[#575556] font-bold text-[20px] mb-5 font-segeo"> {trans('hobbies_request.languages_you_know')}</p>
                  <div className="flex flex-wrap  gap-5 sm:gap-4">
                    {hobbiesData?.languagesYouKnow?.map((val) => (
                      <button
                        key={val.id}
                        className={`text-[#575556] text-[15px]  flex items-center px-4 py-3 rounded-[10px] font-segeo ${languagesYouKnow.includes(val.id)
                          ? "bg-[#F9DADB] text-black border border-[#F9DADB]"
                          : "bg-[#FFFFFF]  border border-[#707070]"
                          }`}
                        onClick={() => handleLanguagesYouKnowButtonClick(val.id)}
                      >
                        {val.name}

                      </button>
                    ))}
                  </div>
                </div>
                  : ""}
              </div>

            </div>
            <div className="flex justify-between mt-5 mx-[1rem] md:mx-[4rem] lg:mx-[4rem] mb-[4rem]">
              <div className="pl-0 md:pl-[4rem]lg:pl-[4rem]">
                <button onClick={handleSubmit} className={`text-[14px]  text-white px-[2.5rem] py-2.5 rounded-md ${isDisabled ? "bg-[#DA3535] opacity-50" : "bg-[#DA3535]"}`} disabled={isDisabled}>{trans('registration.submit__continue')}</button>
              </div>

              {isCommunitySite
                ? null
                : <div className="">
                  <button
                    onClick={() => {
                      if (isCommunitySite) {
                        if (localStorage.getItem("submitpref")) {
                          localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.PAYMENT_PAGE)
                          sessionStorage.setItem(Constants.loginLocalStorageKeys.paymentPage,"true")
                          navigate(RouteSegments.PAYMENT_PAGE)
                        } else {
                          alert("Please Select any hobbies")
                        }

                      } else {
                        localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.PAYMENT_PAGE)
                        sessionStorage.setItem(Constants.loginLocalStorageKeys.paymentPage,"true")
                        navigate(RouteSegments.PAYMENT_PAGE)
                      }
                    }}
                    type="button" className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline">{trans('kalyan_rewards_popup.skip')}</button>
                </div>}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileHobbies;
