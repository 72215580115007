import React, { useContext, useEffect, useMemo, useState } from "react";
import {
  BasicEditTextView,
  BasicTextView,
  SelectInput,
  SelectViewWithHeading,
} from "../../Components/Edit Profile/EditProfileUtills";
import { useFormik } from "formik";
import * as Yup from "yup";
import {
  FamilyBasicEditTextView,
  FamilyEditTextView,
  SelectFamilyInput,
  SelectFamilyNumberInput,
  SelectFamilyViewWithHeading,
} from "./PartnerPreferenceUtils";
import { validateNumber } from "../../Utils";
import { GET, POST } from "../../Services/api_services";
import config from "../../config";
import EndPoints from "../../Constants/EndPoints";
import { LoginContext } from "../../Contexts/LoginContext";
import Constants, { assetOptions, countOptions, nativeOptions } from "../../Constants/constants";
import { useNavigate } from "react-router-dom";
import RouteSegments from "../../Routes/RouteSegment";
import LoaderGif from "../../Gif/loader.gif"
import axios from "axios";
import { useTranslation } from "react-i18next";

import { registerCityList, registerCountryList, registerStateList } from "../../Store/Home/Action";
import { useDispatch, useSelector } from "react-redux";
import { getBasicData } from "../../Store/Login/Action";
import constant, {
  nameNumericList,
  EventKeys,
  EventName,
} from "../../Constants/constants";
let errorNameMessage = ""

const AboutFamily = (props) => {

  const { loginDetail, logout } = useContext(LoginContext);
  const navigate = useNavigate();

  const { t:trans } = useTranslation();


  const [additionalFamDetail, setadditionalFamDetail] = useState([]);
  const [occupationData, setOccupationData] = useState([]);

  const [noSiblings, setNoSiblings] = useState(false);
  const [siblingsDisabled, setSiblingsDisabled] = useState(false);

  const [isIndiaChecked, setIsIndiaChecked] = useState(false);

  const isCommunitySite = localStorage.getItem(Constants.loginLocalStorageKeys.isCommunitySite);

  const [marriedBroCountOptions, setMarriedBroCountOptions] = useState(countOptions);

  const [marriedSisterCountOptions, setMarriedSisterCountOptions] = useState(countOptions);
  const [isAlreadySubmitted, setIsAlreadySubmitted] = useState(false);
  const [isSubmitted, setIsSubmitted] = useState(true);

 

  
    const registerData = useSelector((state) => state?.Home?.rData);


    console.log(noSiblings,'registerDatabb');
    
  const [fatherNameErrorMessage, setFatherNameErrorMessage] = useState("")
  const [motherNameErrorMessage, setMotherNameErrorMessage] = useState("")


  const [fatherNativeErrorMessage, setFatherNativeErrorMessage] = useState("")
  const [motherNativeErrorMessage, setMotherNativeErrorMessage] = useState("")

console.log(fatherNameErrorMessage,'fatherNameErrorMessage');

  const familyInfoFreshAPI = async () => {

    const url = `${config.api.API_URL}${EndPoints.additionalFamilyinfofreshUrl()}`;

    let { statusCode, data } = await GET(url);

    if (statusCode === 200) {
      setadditionalFamDetail(data?.data);
    } else if (statusCode === 401) {
      logout();
    }
  }


  useEffect(() => {
    familyInfoFreshAPI();
  }, [])

  const saveAdditionalFamilyInfo = async (values,setSubmitting) => {

    let request = {
      email: loginDetail()[0],
      userId: loginDetail()[1],
      loginFrom: localStorage.getItem(Constants.loginLocalStorageKeys.loginDevice),

      fathersName:values?.fathersName,
      mothersName:values?.mothersName,

      fathersnativeplace:values?.fathersnativeplace,
      mothersnativeplace:values?.mothersnativeplace,
      
      mothersOccupation:values?.mothersOccupation,
      fathersOccupation:values?.fathersOccupation,
      
      sisters:values.haveSiblings ? null : values?.sisterCount,
      brothers:values.haveSiblings ? null : values?.brotherCount,
      
      marriedSisters:values.haveSiblings ? null : values?.marriedSisterCount,
      marriedBrothers:values.haveSiblings ? null : values?.marriedBrotherCount,
      
      fathersIsdcode:additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.fisdCode || item.isdcode == values?.fisdCode).at(0)?.isdcode,
      fathersMobilenumber:values?.fMobileNumber,
      mothersIsdcode:additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.misdCode || item.isdcode == values?.misdCode).at(0)?.isdcode,
      mothersMobilenumber:values?.motherMobileNumber,
      
      brothersMobilenumber:values.haveSiblings ? null : values?.brotherNumber,
      sistersMobilenumber:values.haveSiblings ? null : values?.sisterNumber,
      
      sistersIsdcode:values.haveSiblings ? null : additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.sisIsdCode || item.isdcode == values?.sisIsdCode).at(0)?.isdcode,
      brothersIsdcode:values.haveSiblings ? null : additionalFamDetail?.isdcode?.filter(item => item.countryId == values?.broIsdCode || item.isdcode == values?.broIsdCode).at(0)?.isdcode,
      
      isMotherAsParentsnumber:values?.isMother ? "Y" : "N",
      isfatherAsParentsnumber:values?.isFather ? "Y" : "N",
      assetIds: values?.assetInfo,

      editedFrom:"afterRegistration",
      isParentsMobilenumberfilled:props?.editMyProfileData?.isParentsMobilenumberfilled,
      ishavenosiblings:values?.haveSiblings ? "Y" : "N",
      livesIn: values?.groomLivesInIndia ? "India" : values?.groomLivesInAbroad ? "Abroad" : "",
      isWorkLocationSame:values?.isWorkLocationSame? true : false,
      workCountry: values?.workCountry,
      workState: values?.workState,
      workCity: values?.workCity,

      fathersnativelocationtype: values?.fnative ? additionalFamDetail?.LocationData?.filter(item => item.locationId == values?.fnative).at(0)?.locationName : null,
      mothersnativelocationtype: values?.mnative ? additionalFamDetail?.LocationData?.filter(item => item.locationId == values?.mnative).at(0)?.locationName : null,
      
      ...(values?.fathersnativeplace != "" && { fatherNativePlaceRequestTypeId:93 }),
      ...(values?.mothersnativeplace != "" && { motherNativePlaceRequestTypeId:94 }),
      ...((values?.livesIn != "" && values?.livesIn != undefined) && { LivesinRequestTypeId:95 }),
      ...((values?.workState != "" && values?.workState != undefined) && { WorkStateRequestTypeId:96 }),
      ...((values?.workCity != ""&& values?.workCity != undefined) && { WorkCityRequestTypeId:97 })

    };

    let { statusCode, data } = await POST(
      `${config.api.API_URL}${EndPoints.saveAdditionInfo()}`,
      request
    );
    setSubmitting(false);
    setIsAlreadySubmitted(true);

    if (statusCode === 200) {
      handleSkip();
    } else if (statusCode === 401) {
      logout();
    }
  };

  const parentNumber = props?.editMyProfileData?.parentsMobileNumber;
  const parentCurrency = props?.editMyProfileData?.parentsCurrency?.countryId;
  const showMother = props?.editMyProfileData?.relationshipId == "5";
  const showFather = props?.editMyProfileData?.relationshipId == "6";
  const parentISDcode = props?.editMyProfileData?.parentsIsdCode;
  const Male = props?.editMyProfileData?.gender == "M"
  const Female = props?.editMyProfileData?.gender == "F"
  const ProfileCountry = props?.editMyProfileData?.country[0]?.name
  const ProfileCountryId = props?.editMyProfileData?.country[0]?.id
  const ProfileState = props?.editMyProfileData?.state[0]?.name
  const ProfileStateId = props?.editMyProfileData?.state[0]?.id
  const ProfileCity = props?.editMyProfileData?.city[0]?.name
  const ProfileCityId = props?.editMyProfileData?.city[0]?.id


  

  const formik = useFormik({

    initialValues: {

      fathersName: props?.editMyProfileData?.fathersName ?? "",
      fathersOccupation: props?.editMyProfileData?.fathersOccupation.length > 0  ? 
                        props?.editMyProfileData?.fathersOccupation[0]?.id : null,
      fisdCode: showFather ? parentISDcode : "+91",
      fMobileNumber: showFather ? parentNumber : "",
      isFather: showFather ? true : false,

      mothersName: props?.editMyProfileData?.mothersName ?? "",
      mothersOccupation: props?.editMyProfileData?.mothersOccupation.length > 0 ? props?.editMyProfileData?.mothersOccupation[0]?.id : null,
      misdCode: showMother ? parentISDcode : "+91",
      motherMobileNumber: showMother ? parentNumber : "",
      isMother: showMother ? true : false,

      haveSiblings:noSiblings == true ? true : false,
      
      brotherCount: props?.editMyProfileData?.brothers ?? "",
      marriedBrotherCount: props?.editMyProfileData?.marriedBrothers ?? "",
      broIsdCode: props?.editMyProfileData?.brothersIsdcode ?? "+91",
      brotherNumber: props?.editMyProfileData?.brothersMobilenumber ?? "",
      
      sisterCount:props?.editMyProfileData?.sisters ?? "",
      marriedSisterCount: props?.editMyProfileData?.marriedSisters ?? "",
      sisIsdCode:props?.editMyProfileData?.sistersIsdcode ?? "+91",
      sisterNumber:props?.editMyProfileData?.sistersMobilenumber ?? "",

      fathersnativeplace:props?.editMyProfileData?.fathersnativeplace ?? "",
      mothersnativeplace: props?.editMyProfileData?.mothersnativeplace ?? null,
      fnative: props?.editMyProfileData?.fathersnativelocationtype ?? "",
      mnative: props?.editMyProfileData?.mothersnativelocationtype ??"",

      assetInfo:props?.editMyProfileData?.asset?.map((item) => 
                assetOptions.find(option => option.label === item.name)?.value
              ) ?? [],
    

      countryId: Constants.indiaIsdCodeID,
      countryNameId: 499,
      groomLivesInIndia:props?.editMyProfileData?.livesIn == "India" ? true : false ?? "",
      groomLivesInAbroad:props?.editMyProfileData?.livesIn == "India" ? false : true ?? "",
      isWorkLocationSame:props.editMyProfileData?.isWorkLocationSame ?? "",
      workCountry: props?.editMyProfileData?.workCountry?.length > 0 ? props?.editMyProfileData?.workCountry[0]?.id : props?.data?.country,
      workState: props?.editMyProfileData?.workState?.length > 0  ? props?.editMyProfileData?.workState[0]?.id :  props?.data?.state,
      workCity: props?.editMyProfileData?.workCity?.length > 0 ? props?.editMyProfileData?.workCity[0]?.id : props?.data?.city,
    },

    enableReinitialize: true,
    validationSchema: Yup.object().shape({

      fMobileNumber: Yup.string()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { fisdCode } = this.parent;
            if (fisdCode == "+91" || fisdCode == 499) {
              if (value) {
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "fMobileNumber",
                  });
                }
              }
            } else {
              if (value) {
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "fMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { motherMobileNumber, brotherNumber, sisterNumber } = this.parent;
            if (value && (value == motherMobileNumber || value == brotherNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "fMobileNumber",
              });
            }
            return true;
          }
        ).when("isFather", (isFather, schema) => {

          if (isFather && isFather[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
      motherMobileNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { misdCode } = this.parent;
            if (misdCode == "+91" || misdCode == 499) {
              if (value) {
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "motherMobileNumber",
                  });
                }
              }
            } else {
              if (value) {
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "motherMobileNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber, brotherNumber, sisterNumber } = this.parent;
            if (value && (value == fMobileNumber || value == brotherNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "motherMobileNumber",
              });
            }
            return true;
          }
        ).when("isMother", (isMother, schema) => {

          if (isMother && isMother[0]) {
            return schema.required("Mobile Number is Required");
          } else {
            return schema;
          }
        }),
      brotherNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { broIsdCode } = this.parent;
            if (broIsdCode == "+91" || broIsdCode == 499) {
              if (value) {
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "brotherNumber",
                  });
                }
              }
            } else {
              if (value) {
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "brotherNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber, motherMobileNumber, sisterNumber } = this.parent;
            if (value && (value == fMobileNumber || value == motherMobileNumber || value == sisterNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "brotherNumber",
              });
            }
            return true;
          }
        ),
      sisterNumber: Yup.string().nullable()
        .test(
          "valid-number",
          "Please enter a valid Mobile Number",
          function (value) {
            const { sisIsdCode } = this.parent;
            if (sisIsdCode == "+91" || sisIsdCode == 499) {
              if (value) {
                if (value?.length !== 10) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "sisterNumber",
                  });
                }
              }
            } else {
              if (value) {
                if (value?.length < 7 || value?.length > 15) {
                  return this.createError({
                    message: trans('family.please_enter_a_valid_mobile_nu'),
                    path: "sisterNumber",
                  });
                }
              }
            }
            return true;
          }
        ).test(
          'not-duplicate',
          'Mobile number already given',
          function (value) {
            const { fMobileNumber, brotherNumber, motherMobileNumber } = this.parent;
            if (value && (value == fMobileNumber || value == brotherNumber || value == motherMobileNumber)) {
              return this.createError({
                message: "Mobile number already given",
                path: "sisterNumber",
              });
            }
            return true;
          }
        ),
        fathersnativeplace: Yup.string().nullable(),
        fnative: Yup.string()
          .nullable()
          .test(
            "valid",
            "Please enter a father's native or select a location type",
            function (value) {
              const { fathersnativeplace, fnative } = this.parent;
              if (!fathersnativeplace && value) {
                return this.createError({
                  message: "Please enter father's native",
                  path: "fathersnativeplace",
                });
              }

              if (fathersnativeplace && !value) {
                return this.createError({
                  message: "Please select (Village/Town/District/City)",
                  path: "fnative",
                });
              }

              return true;
            }
          ),
        mothersnativeplace: Yup.string().nullable(),
        mnative: Yup.string()
          .nullable()
          .test(
            "valid",
            "Please enter a mother's native or select a location type",
            function (value) {
              const { mothersnativeplace, mnative } = this.parent;
              console.log(mothersnativeplace , value,'klkl');
              if (!mothersnativeplace && value) {
                return this.createError({
                  message: "Please enter mother's native",
                  path: "mothersnativeplace",
                });
              }

              if (mothersnativeplace && !value) {
                return this.createError({
                  message: "Please select (Village/Town/District/City)",
                  path: "mnative",
                });
              }

              return true;
            }
          ),
    }),

    onSubmit: async (values,{setSubmitting}) => {
      if (fatherNameErrorMessage || formik.errors.fathersName || motherNameErrorMessage || formik.errors.mothersName
        || fatherNativeErrorMessage || motherNativeErrorMessage || formik.errors.fathersnativeplace || formik.errors.fnative || formik.errors.mothersnativeplace || formik.errors.mnative
      ) {
        console.log("Submission blocked due to validation errors");
        setSubmitting(false); // Ensure Formik stops the submission process
        return; // Exit the submission handler
    }

    // If no errors, proceed with the API call
    saveAdditionalFamilyInfo(values, setSubmitting);
      
    },
  });
  useEffect(() => {
    if (props?.editMyProfileData?.ishavenosiblings === 'Y') {
      setNoSiblings(true);
    } else {
      setNoSiblings(false);
    }
  }, []);
  const onNameChange = (e,field) => {
    let text = e.target.value ?? "";
    text = text
      .trimStart()
      .replace(/[^a-zA-Z ]/g, "")
      .replace(/  +/g, " ");
    formik.setFieldValue(field, text);
  };

  
  const dispatch = useDispatch();

  useEffect(() => {
    if (additionalFamDetail?.occupationData) {
      setOccupationData(additionalFamDetail?.occupationData?.map(item => ({
        value: item.id,
        label: item.name,
        disabled: item.heading == "Y" ? true : false
      })))
    }
  }, [additionalFamDetail])

  useEffect(() => {
    if (formik.values.brotherCount || formik.values.marriedBrotherCount || formik.values.brotherNumber || formik.values.sisterCount || formik.values.marriedSisterCount || formik.values.sisterNumber) {
      setSiblingsDisabled(true);
    } else {
      setSiblingsDisabled(false);
    }
    
  }, [formik.values.brotherCount,formik.values.marriedBrotherCount,formik.values.brotherNumber,formik.values.sisterCount,formik.values.marriedSisterCount,formik.values.sisterNumber])
  
  
    const handleSkip = () => {

      setIsAlreadySubmitted(true)
      if(isCommunitySite){
        if(props.isNextAstro){
          return props?.setShowAstroForm(true)
        }
        if(localStorage.getItem("submitpref")){
          localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.MATCH_GROUP)
          navigate(RouteSegments.MATCH_GROUP)
        }
      }else{
        if(props.isNextAstro){
          return props?.setShowAstroForm(true)
        }
        if(localStorage.getItem(Constants.loginLocalStorageKeys.isOriginalImageUploaded) == "true"){
          // localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.DOWNLOAD_PAGE)
          navigate(RouteSegments.PROFILE_PREVIEW,{state : {isFrom : "afterreg"}})
        }else {
          localStorage.setItem(Constants.loginLocalStorageKeys.loginCurrentPage, RouteSegments.UPLOAD_PHOTO_PAGE)
          sessionStorage.setItem("photoPage",true);
          navigate(RouteSegments.UPLOAD_PHOTO_PAGE,{state : { isFrom : "previous" }})
        }
      }
  }

  const nameCheckList = async (value, e, field) => {
    let request = {
      name: value
    };
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
      request
    );
    if (data.data.status === "Success") {
      formik.setFieldError(field, data.data.data.Messagge)
    }
  }
  
    const motherNameCheckList = async (value,e,field) => {
      let request = {
        name: value
      };
      let data = await axios.post(
        `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
        request
      );
        if (data.data.status === "Success") {
          errorNameMessage = data?.data?.data?.Messagge
          setMotherNameErrorMessage(data?.data?.data?.Messagge)
        }
  };


  const fatherNameCheckList = async (value,e,field) => {
    let request = {
      name: value
    };
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
      request
    );
      if (data.data.status === "Success") {
        errorNameMessage = data?.data?.data?.Messagge
        setFatherNameErrorMessage(data?.data?.data?.Messagge)
      }
};


  const fathernativeCheckList = async (value,e,field) => {
    let request = {
      name: value
    };
    let data = await axios.post(
      `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
      request
    );
      if (data.data.status === "Success") {
        errorNameMessage = data?.data?.data?.Messagge
        setFatherNativeErrorMessage(data?.data?.data?.Messagge)

      }
};


const mothernativeCheckList = async (value,e,field) => {
  let request = {
    name: value
  };
  let data = await axios.post(
    `${config.api.API_URL}${EndPoints.validateNameUrl()}`,
    request
  );
    if (data.data.status === "Success") {
      errorNameMessage = data?.data?.data?.Messagge
      setMotherNativeErrorMessage(data?.data?.data?.Messagge)
    }
};

  useEffect(() => {

    if(formik?.values?.brotherCount){
      if(formik?.values?.marriedBrotherCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedBrotherCount', "")
      }else{
        setMarriedBroCountOptions(countOptions.filter(val => val.value <= formik.values.brotherCount))
      }
    }else if(formik?.values?.brotherCount == 0){
      formik.setFieldValue('marriedBrotherCount', "");
    }

  }, [formik.values.brotherCount])

  useEffect(() => {

    if(formik?.values?.sisterCount){
      if(formik?.values?.marriedSisterCount > formik?.values?.brotherCount){
        formik.setFieldValue('marriedSisterCount', "")
      }else{
      setMarriedSisterCountOptions(countOptions.filter(val => val.value <= formik.values.sisterCount))
      }
    }else if(formik?.values?.sisterCount == 0){
      formik.setFieldValue('marriedSisterCount', "");
    }

  }, [formik.values.sisterCount])

 

  useEffect(() => {
    dispatch(
      getBasicData(localStorage.getItem(constant.loginLocalStorageKeys.loginId))
    );
    // dispatch(registerSubCasteList("122"))
  }, []);




      const [workCountryDropDown, setworkCountryDropDown] = useState([]);

      const [workStateDropDown, setworkStateDropDown] = useState([]);
      const [workCityDropDown, setworkCityDropDown] = useState([]);
 
  
    const { basicData } = useSelector((state) => ({
      basicData: state.Login?.basicData,
    }));

    console.log(basicData,"basicData");
    

 
console.log(formik.values.workCountry,formik.values.workState,formik.values.workCity,'hhhhhhhhhhhhh');

    useEffect(() => {
      setworkCountryDropDown(basicData?.data?.isdcode?.map((item)=>({
        value: item.id, // Use the country ID as the value
        label: item.country, // Use the country name as the label
      })));
        
      if(formik.values.groomLivesInAbroad){
        setworkCountryDropDown(basicData?.data?.isdcode?.filter(item=>item.country != "India").map((item)=>({
          value: item.id, // Use the country ID as the value
          label: item.country, // Use the country name as the label
        })));
      } else if (formik.values.groomLivesInIndia ) {
        setworkCountryDropDown(
          basicData?.data?.isdcode?.map((item) => ({
                value: item.id,
                label: item.country, 
            }))
        );
    } else {
        setworkCountryDropDown([]);
    }


      
    }, [ basicData?.data?.isdcode ,formik.values.groomLivesInAbroad ,formik.values.groomLivesInIndia]);
  



  const workStateDropDownOption = workStateDropDown?.map((item)=>({
    value: item.id, // Use the country ID as the value
    label: item.name, // Use the country name as the label

  }))
    

  const workCityDropDownOption = workCityDropDown?.map((item)=>({
    value: item.id, // Use the country ID as the value
    label: item.name, // Use the country name as the label
  }))
  
console.log({workCityDropDown,workStateDropDown});


  console.log({workCityDropDownOption,workStateDropDownOption});


      const OnChangeOption = (name, value) => {

       console.log(name,value,"valuename");
       
        if (name === "workCountry") {
            apiCallState(value);
            formik.setFieldValue("workState","");
            formik.setFieldValue("workCity","");

        } else if (name === "workState") {
         
            apiCallCity(value);
            formik.setFieldValue("workCity","");
        } 

        formik.setFieldValue(name, value)
    }


    useEffect(()=>{

      if(props?.editMyProfileData?.country){
        apiCallState(props?.editMyProfileData?.country[0]?.id)
      }
      if(props?.editMyProfileData?.state){
      
       apiCallCity(props?.editMyProfileData?.state[0]?.id )
      }
     
     
    },[props?.editMyProfileData?.country  ,props?.editMyProfileData?.state])


    const apiCallState = async (countryID ,from) => {

        try {
          let { statusCode, data } = await GET(
            `${config.api.API_URL}${EndPoints.getStateUrl(countryID)}`
          );
      
          if (statusCode === 200) {
            setworkStateDropDown(data.data.states)
            if(from == "retain"){
                const temp = [];
                data?.data?.states?.forEach(state => {
                    if (formik?.values?.workState?.includes(state.id)) {
                        temp.push(state.id)
                    }
                });
                formik.setFieldValue("workState", temp);
                apiCallCity(temp);

            }
            

            }
        }
        catch (error) {
            console.log("error on fetch state", error);
        }
    };

    const apiCallCity = async (stateID) => {

        try {
          let { statusCode, data } = await GET(
            `${config.api.API_URL}${EndPoints.getCityUrl(stateID)}`
          );
      
          if (statusCode === 200) {
            if (data.status == "Success") {
              setworkCityDropDown(data.data.cities)
              // const temp = [];
              // data?.data?.cities?.forEach(city => {
              //     if (formik.values?.workCity?.includes(city.id)) {
              //         temp.push(city.id)
              //     }
              // });
              // formik.setFieldValue("workCity", temp);
              
          }
            }
        }
        catch (error) {
            console.log("Error on fetch city", error);
        }

    };



const [isModified, setIsModified] = useState(false);


useEffect(() => {
  // Check if assetInfo has been modified or if it's not empty
  if (formik.values.assetInfo?.length > 0 ||
    formik.values.fathersName?.length > 0 ||
formik.values.fathersOccupation?.length > 0 ||
formik.values.fMobileNumber?.length > 0 ||
formik.values.isFather?.length > 0 ||
formik.values.mothersName?.length > 0 ||
formik.values.mothersOccupation?.length > 0 ||
(formik.values.motherMobileNumber?.length > 0 && !showMother)||
formik.values.isMother?.length > 0  ||
formik.values.haveSiblings?.length > 0 ||
formik.values.brotherCount ||
formik.values.sisterCount ||
formik.values.marriedSisterCount?.length > 0 ||
formik.values.groomLivesInIndia ||
formik.values.groomLivesInAbroad ||
formik.values.brotherNumber?.length >0 ||
formik.values.sisterNumber?.length > 0

  ) {
    setIsModified(true); // Enable Submit button
  } else {
    setIsModified(false); // Disable Submit button
  }
}, [formik.values.assetInfo ,formik.values.fathersName ,formik.values.fathersOccupation,
  formik.values.fMobileNumber,formik.values.isFather,formik.values.mothersName,
  formik.values.mothersOccupation,formik.values.motherMobileNumber,
  formik.values.isMother,formik.values.haveSiblings,
  formik.values.brotherCount,
  formik.values.sisterCount,formik.values.marriedSisterCount,formik.values.brotherNumber,
  formik.values.sisterNumber,formik.values.groomLivesInIndia,formik.values.groomLivesInAbroad

]); // Trigger this effect whenever assetInfo changes

console.log(formik.values.groomLivesInIndia ,formik.values.groomLivesInAbroad,"formik.values.groomLivesInIndia ");

  const FatherNativeErrorMsg =()=>{
    if(fatherNativeErrorMessage){
      return("Please enter a valid location.")
    }
    if(formik.errors.fathersnativeplace){
      return("Please enter father's native")
    }
   
  }
  const MotherNativeErrorMsg = () =>{

    if(motherNativeErrorMessage)
      return("Please enter a valid location.")

    if(formik.errors.mothersnativeplace){
      return("Please enter mother's native")
    }
  }

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <fieldset disabled={isAlreadySubmitted }>
        <div>
          <div className="p-0 md:p-0 md:py-[1rem] lg:px-[2rem] bg-[#FFFFFF]">
            <div className="mx-2">
              <div className="mt-[4rem] md:mt-[2rem] lg:mt-[0rem]">
                <div className="space-y-2">
                  <p className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">
                  {trans('tell_us_more_about_your_family.tell_us_more_about_your_family')}
                  </p>
                </div>
              </div>

              <FamilyEditTextView
                title={trans('view_profile.fathers_name')}
                inputType={"text"}
                error={fatherNameErrorMessage== ''? '': fatherNameErrorMessage} // Show API or Formik error
                inputID={"fathersName"}
                inputName={"fathersName"}
                value={formik.values.fathersName}
                onChange={(e)=>{
                  fatherNameCheckList(e.target.value,e,"fathersName");
                    setIsModified(true);
                    
                    let text = e.target.value ?? "";
                    text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                    text = text.charAt(0).toUpperCase() + text.slice(1)
                    var splitStr = text.toLowerCase().split(' ');
                    for (var i = 0; i < splitStr.length; i++) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                    }
                    text =  splitStr.join(' '); 
                    e.target.value = text;
                    if ((e.target.value.split(".").length - 1) <= 1) {
                      formik.handleChange(e)
                      
                    }
                }}
                // error={formik.errors.fathersName}
                required={false}
                menu="Occupation"
                placeholder= {trans('tell_us_more_about_your_family.enter_fathers_name')}
                className="placeholder-color"
              />
              <SelectFamilyViewWithHeading
                title={trans('view_profile.fathers_occupation')}
                inputID={"fathersOccupation"}
                disabled={isAlreadySubmitted}
                inputName={"fathersOccupation"}
                value={formik.values.fathersOccupation}
                onChange={(selected, ev) => {
                  setIsModified(true);
                  formik.setFieldValue("fathersOccupation", selected);
                }}
                error={formik.errors.fathersOccupation}
                options={occupationData}
                required={false}
                menu="Occupation"
              />
              <div className="grid grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4 md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center">
                  <p className="md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold">
                    Father's Native
                  </p>
                </div>
                <div className="md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[0.5rem] md:gap-[1rem] lg:space-x-5 items-center pt-2 md:pt-0 lg:pt-0">
                  
                    <div className="col-span-1">
                      <SelectFamilyNumberInput
                        title="Father's Native Dropdown"
                        name="fnative"
                        menu="Numbercode"
                        className={"lg:!w-[7rem] xl:!w-[8rem] 2xl:!w-[10rem] w-[5rem] md:w-[5rem]"}
                        value={formik.values.fnative}
                        onChange={(selected, ev) => {
                          setIsModified(true);
                          formik.setFieldValue("fnative", selected);
                        }}
                        placeholder={'Village/Town/District/City'}
                        defaultValue={formik.values.fnative}
                        options={[{ value: "", label: "Village/Town/District/City", disabled: true }, ...nativeOptions]}
                        disabled={isAlreadySubmitted}
                      />
                    </div>
                    <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                      <FamilyBasicEditTextView
                        title="Father's Native"
                        inputID="fathersnativeplace"
                        inputName="fathersnativeplace"
                        menu="FathersName"
                        inputType="text"
                        className="placeholder-color"
                        placeholder="Enter Father's Native (District / village / city)"
                        value={formik.values.fathersnativeplace}
                        onChange={(e) => {
                          fathernativeCheckList(e.target.value, e, "fathersnativeplace");
                          let text = e.target.value ?? "";
                          text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                          text = text.charAt(0).toUpperCase() + text.slice(1);
                          const splitStr = text.toLowerCase().split(" ");
                          for (let i = 0; i < splitStr.length; i++) {
                            splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                          }
                          text = splitStr.join(" ");
                          e.target.value = text;
                          if ((e.target.value.split(".").length - 1) <= 1) {
                            formik.handleChange(e);
                            setIsModified(true);
                          }
                        }}
                        error={formik.errors.fathersnativeplace}
                        required={false}
                        touched={formik.touched.fathersnativeplace}
                        fatherNativeErrorMessage={fatherNativeErrorMessage}
                      />
                    </div>
                  
                  {formik.errors.fnative && formik.touched.fnative && (
                    <div className="col-span-5 md:col-span-3 lg:col-span-5">
                      <p className={`text-red-500 text-sm whitespace-nowrap lg:px-[1rem] lg:ml-[-2rem] ${fatherNativeErrorMessage ? 'mt-2' : ''}`}>{formik.errors.fnative}</p>
                    </div>
                  )}
                </div>
              </div>

                  {/* <FamilyEditTextView
                title={"Father's Native"}
                inputType={"text"}
                error={FatherNativeErrorMsg()}
                inputID={"fathersnativeplace"}
                inputName={"fathersnativeplace"}
                value={formik.values.fathersnativeplace}
                onChange={(e)=>{
                  fathernativeCheckList(e.target.value,e,"fathersnativeplace");
                  setIsModified(true);
                    let text = e.target.value ?? "";
                    text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                    text = text.charAt(0).toUpperCase() + text.slice(1)
                    var splitStr = text.toLowerCase().split(' ');
                    for (var i = 0; i < splitStr.length; i++) {
                        splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                    }
                    text =  splitStr.join(' '); 
                    e.target.value = text;
                    if ((e.target.value.split(".").length - 1) <= 1) {
                      formik.handleChange(e)
                    }
                }}
                // error={formik.errors.fathersName}
                required={false}
                menu="Occupation"
                 placeholder="Enter Father's Native (District / village / city)"
                className="placeholder-color"
              /> */}

              <div className="grid  grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4  md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center   ">
                  <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold  ">
                  {trans('tell_us_more_about_your_family.fathers_mobile_number')}
                  </p>
                </div>
                <div className=" md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[0.5rem] md:gap-[1rem] lg:gap-4 items-center pt-2 md:pt-0 lg:pt-0">
                  <div className="col-span-1">
                    <SelectFamilyNumberInput
                      name="fisdCode"
                      title="Parent's IsdCode"
                      value={formik.values.fisdCode}
                      onChange={(selected, ev) => {
                        setIsModified(true);
                        formik.setFieldValue("fisdCode", selected);
                      }}
                      error={formik.errors.fisdCode}
                      defaultValue={formik.values.fisdCode}
                      options={[{ value: "", label: "Select", disabled: true }, ...props?.parentsIsdCodeDropDown]}
                      menu="Numbercode"
                      disabled={showFather || isAlreadySubmitted}
                      className={showFather == true ? "cursor-not-allowed lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]" : "lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]"}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                    <FamilyBasicEditTextView
                      inputID={"fMobileNumber"}
                      inputName={"fMobileNumber"}
                      value={formik.values.fMobileNumber}
                      onChange={async(e) => {
                        setIsModified(true);
                        let inputValue = e.target.value;
                        if (validateNumber(inputValue)) {
                          await formik.setFieldValue("fMobileNumber", inputValue)
                          if (!showMother) {
                            if (inputValue.length == 10 && formik?.values?.fisdCode == "+91") {
                              await formik.setFieldValue("isFather", true);
                            } else if ((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.fisdCode != "+91") {
                              await formik.setFieldValue("isFather", true);
                            }
                            else {
                              await formik.setFieldValue("isFather", false);
                            }
                          }
                        }
                      }}
                      error={formik.errors.fMobileNumber}
                      inputType={"tel"}
                      menu="Numbercode"
                      disabled={showFather}
                      className={showFather == true ? "cursor-not-allowed" : "placeholder-color "}
                      placeholder= {trans('tell_us_more_about_your_family.enter_fathers_mobile_number')}
                    />
                  </div>
                </div>
              </div>
              {showMother ? <></> :
              <div className="grid grid-cols-12 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                <label className="col-span-12 md:col-start-5 md:col-span-5 lg:col-start-5 lg:col-span-6 relative flex mt-5 md:pl-2 lg:pl-2">
                  <input
                    id="isFather"
                    name="isFather"
                    type="checkbox"
                    checked={formik.values.isFather}
                    value={formik.values.isFather}
                    onChange={async(e)=>{
                      setIsModified(true);
                      await formik.setFieldValue("isFather",e.target.checked);
                      if(e.target.checked){
                        await formik.setFieldValue("isMother",false);
                      }
                    }}
                    className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${showFather == true ? "cursor-not-allowed" : ""}`}
                    autoComplete="off"
                    disabled={showFather}
                  />
                  <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <p className="hover:text-[#151515] pl-2">
                    {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                    </p>
                  </span>
                </label>
              </div>
              }

             
              <FamilyEditTextView
                title={trans('view_profile.mothers_name')}
                inputType={"text"}
                inputID={"mothersName"}
                inputName={"mothersName"}
                value={formik.values.mothersName}
                onChange={(e)=>{
                  motherNameCheckList(e.target.value,e,"mothersName");
                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                    splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                  }
                  text = splitStr.join(' ');
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                    setIsModified(true);
                    
                  }
              }}
                error={motherNameErrorMessage == '' ? '' : motherNameErrorMessage}
                required={false}
                menu="MothersName"
                placeholder= {trans('tell_us_more_about_your_family.enter_mothers_name')}
                className="placeholder-color"
              />


              <SelectFamilyViewWithHeading
                title={trans('view_profile.mothers_occupation')}
                inputID={"mothersoccupation"}
                disabled={isAlreadySubmitted}
                inputName={"mothersoccupation"}
                value={formik.values.mothersOccupation}
                onChange={(selected, ev) => {
                  setIsModified(true);
                  formik.setFieldValue("mothersOccupation", selected);
                }}
                error={formik.errors.mothersOccupation}
                options={occupationData}
                required={false}
                menu="Occupation"
              />

              <div className="grid grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4 md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center">
                  <p className="md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold">
                  Mother's Native
                  </p>
                </div>
                <div className="md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[0.5rem] md:gap-[1rem] lg:space-x-5 items-center pt-2 md:pt-0 lg:pt-0">
                  <div className="col-span-1">
                    <SelectFamilyNumberInput
                      title="Mother's Native Dropdown"
                      name="mnative"
                      menu="Numbercode"
                      className="lg:!w-[7rem] xl:!w-[8rem] 2xl:!w-[10rem] w-[5rem] md:w-[5rem]"
                      value={formik.values.mnative}
                      onChange={(selected, ev) => {
                        setIsModified(true);
                        formik.setFieldValue("mnative", selected);
                      }}
                      placeholder="Village/Town/District/City"
                      defaultValue={formik.values.mnative}
                      options={[{ value: "", label: "Village/Town/District/City", disabled: true }, ...nativeOptions]}
                      disabled={isAlreadySubmitted}
                    />
                  </div>
                  <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                    <FamilyBasicEditTextView
                      title="Mother's Native"
                      inputID="mothersnativeplace"
                      inputName="mothersnativeplace"
                      menu="MothersName"
                      inputType="text"
                      className="placeholder-color"
                      placeholder="Enter Mother's Native (District / village / city)"
                      value={formik.values.mothersnativeplace}
                      onChange={(e) => {
                        mothernativeCheckList(e.target.value, e, "mothersnativeplace");
                        let text = e.target.value ?? "";
                        text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                        text = text.charAt(0).toUpperCase() + text.slice(1);
                        const splitStr = text.toLowerCase().split(" ");
                        for (let i = 0; i < splitStr.length; i++) {
                          splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
                        }
                        text = splitStr.join(" ");
                        e.target.value = text;
                        if ((e.target.value.split(".").length - 1) <= 1) {
                          formik.handleChange(e);
                          setIsModified(true);
                        }
                      }}
                      error={formik.errors.mothersnativeplace}
                      required={false}
                      touched={formik.touched.mothersnativeplace}
                      motherNativeErrorMessage={motherNativeErrorMessage}
                    />
                  </div>
                  {formik.errors.mnative && formik.touched.mnative && (
                    <div className="col-span-5 md:col-span-3 lg:col-span-5">
                      <p className={`text-red-500 text-sm whitespace-nowrap lg:px-[1rem] lg:ml-[-2rem] ${motherNativeErrorMessage ? 'mt-2' : ''}`}>{formik.errors.mnative}</p>
                    </div>
                  )}
                  
                </div>
              </div>

             {/* <FamilyEditTextView
                title={"Mother's Native"}
                inputType={"text"}
                inputID={"mothersnativeplace"}
                inputName={"mothersnativeplace"}
                value={formik.values.mothersnativeplace}
                onChange={(e)=>{
                  mothernativeCheckList(e.target.value,e,"mothersnativeplace");
                  let text = e.target.value ?? "";
                  text = text.trimStart().replace(/[^a-zA-Z .]/g, "").replace(/  +/g, " ");
                  text = text.charAt(0).toUpperCase() + text.slice(1)
                  var splitStr = text.toLowerCase().split(' ');
                  for (var i = 0; i < splitStr.length; i++) {
                      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);     
                  }
                  text =  splitStr.join(' '); 
                  e.target.value = text;
                  if ((e.target.value.split(".").length - 1) <= 1) {
                    formik.handleChange(e)
                    setIsModified(true);
                    
                  }
              }}
              error={MotherNativeErrorMsg()}
               required={false}
                menu="MothersName"
                placeholder="Enter Mother's Native (District / village / city)"
                className="placeholder-color"
              /> */}
            <div className="grid  grid-cols-10 md:grid-cols-9 lg:grid-cols-9 mt-4  md:mt-[2rem]">
                <div className="col-span-9 md:col-span-3 lg:col-span-3 flex items-center   ">
                  <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F] font-semibold  ">
                  {trans('tell_us_more_about_your_family.mothers_mobile_number')}
                  </p>
                </div>
                <div className=" md:col-span-1 lg:col-span-1 hidden md:flex lg:flex items-center justify-center">
                  <p className="font-bold">:</p>
                </div>
                <div className="col-span-12 md:col-span-5 lg:col-span-5 md:pl-[0.5rem] grid grid-cols-3 md:grid-cols-3 lg:grid-cols-10 gap-[0.5rem] md:gap-[1rem] lg:gap-4 items-center pt-2 md:pt-0 lg:pt-0">
                  <div className="col-span-1">
                    <SelectFamilyNumberInput
                      name="misdCode"
                      title="Mother's IsdCode"
                      value={formik.values.misdCode}
                      onChange={(selected, ev) => {
                        setIsModified(true);
                        formik.setFieldValue("misdCode", selected);
                      }}
                      error={formik.errors.misdCode}
                      defaultValue={formik.values.misdCode}
                      options={[{ value: "", label: "Select", disabled: true }, ...props?.parentsIsdCodeDropDown]}
                      menu="Numbercode"
                      disabled={showMother || isAlreadySubmitted}
                      className={`{${showMother == true ? "cursor-not-allowed " : ""} lg:!w-[6rem] xl:!w-[7rem] 2xl:!w-[9rem]`}

                    />
                  </div>
                  <div className="col-span-2 md:col-span-2 lg:col-span-6 lg:col-start-3">
                    <FamilyBasicEditTextView
                      inputID={"motherMobileNumber"}
                      inputName={"motherMobileNumber"}
                      value={formik.values.motherMobileNumber}
                      onChange={async(e) => {
                        setIsModified(true);
                        let inputValue = e.target.value;
                        if (validateNumber(inputValue)) {
                          await formik.setFieldValue("motherMobileNumber", inputValue)
                          if (!showFather) {
                            if (inputValue.length == 10 && formik?.values?.misdCode == "+91") {
                              await formik.setFieldValue("isMother", true);
                            } else if ((inputValue.length < 7 || inputValue.length > 15) && formik?.values?.misdCode != "+91") {
                              await formik.setFieldValue("isMother", true);
                            }
                            else {
                              await formik.setFieldValue("isMother", false);
                            }
                          }
                        }
                      }}
                      error={formik.errors.motherMobileNumber}
                      inputType={"tel"}
                      menu="Numbercode"
                      disabled={showMother}
                      className={showMother == true ? "cursor-not-allowed placeholder-color" : "placeholder-color "}
                      placeholder= {trans('tell_us_more_about_your_family.enter_mothers_mobile_number')}
                    />
                  </div>
                </div>
              </div>
              {showFather ? <></> :
              <div className="grid grid-cols-12 md:grid-cols-9 lg:grid-cols-9 lg:mr-0">
                <label className="col-span-12 md:col-start-5 md:col-span-5 lg:col-start-5 lg:col-span-6 relative flex mt-5 md:pl-2 lg:pl-2">
                  <input
                    id="isMother"
                    name="isMother"
                    type="checkbox"
                    // checked={formik.values.isFather == true ? false :formik.values.isMother}
                    checked={formik.values.isFather == true ? '' : formik.values.isMother}
                    value={formik.values.isMother}
                    onChange={async(e)=>{
                      
                      await formik.setFieldValue("isMother",e.target.checked);
                      if(e.target.checked){
                        await formik.setFieldValue("isFather",false);
                        setIsModified(true);
                      }else{
                        setIsModified(false);
                      }
                    }}
                    className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${showMother == true ? "cursor-not-allowed" : ""}`}
                    autoComplete="off"
                    disabled={showMother}
                  />
                  <span className=" text-[13px] md:ml-[3px] lg:ml-[5px]">
                    <p className="hover:text-[#151515] pl-2">
                    {trans('tell_us_more_about_your_family.add_this_number_to_my_profile')}
                    </p>
                  </span>
                </label>
              </div>
              }
            </div>
          </div>
        </div>
        <div>
          <div className="p-0 lg:p-[2rem] bg-[#FFFFFF] ">
            <div className="mx-2">
              <div className="mt-[2rem] md:flex justify-start md:justify-between items-center">
                <div className="space-y-2">
                  <p className="text-[#D63838] font-bold text-[18px] md:text-[18px] lg:text-[22px]">Siblings</p>
                </div>
                <div>
                <label className={`relative pt-3 md:pt-0 md:justify-center flex ${siblingsDisabled ? "cursor-not-allowed" : ""}`}>
                    <input
                      id="haveSiblings"
                      name="haveSiblings"
                      type="checkbox"
                      checked={formik.values.haveSiblings}
                      onChange={(e) => 
                        {
                          if(e.target.checked){
                            setIsModified(true);
                            formik.handleChange(e)
                            setNoSiblings(e.target.checked)
                          }else {
                            setIsModified(false);
                            setNoSiblings(false)
                          }
                         
                        }}
                      className={`mr-3 relative top-[2px] h-[1.2rem] w-[1.2rem] accent-red-500 border border-[#F18181]  ${siblingsDisabled ? "cursor-not-allowed" : ""}`}
                      autoComplete="off"
                      disabled={siblingsDisabled}
                    />
                    <span className="text-[13px] md:ml-[3px] lg:ml-[5px]">
                      <p className="hover:text-[#151515] pl-2">
                        {Male ? "No brothers or sisters to groom" : Female ? "No brothers or sisters to bride" : ''}
                      </p>
                    </span>
                  </label>
                </div>
              </div>

              <div className="grid grid-cols-2 md:grid-cols-12 lg:grid-cols-2 items-center gap-4 md:gap-4 lg:gap-0">
                <div className="md:col-span-6 lg:col-span-1">
                  <SelectFamilyInput
                      title={trans('view_profile.brothers')}
                      menu="aferRegister"
                      name="brothercount"
                      value={formik.values.brotherCount ? formik.values.brotherCount :"Select"}
                      error={formik.errors.brotherCount}
                      defaultValue={formik.values.familyStatus}
                      disabled={noSiblings || isAlreadySubmitted}
                      options={[{ value: null, label: "Select", disabled: true },...countOptions]}
                      onChange={(selected) =>{
                        setIsModified(true);
                        formik.setFieldValue("brotherCount", selected)
                      }}
                      required={false}
                      placeholder="select"
                      className="placeholder-color"
                  />
                </div>

                <div className="md:col-span-6 lg:col-span-1">
                  <SelectFamilyInput
                      title= {trans('siblings.married')}
                      menu="aferRegister"
                      name="marriedBrotherCount"
                      value={formik.values.marriedBrotherCount ? formik.values.marriedBrotherCount :"Select"}
                      error={formik.errors.marriedBrotherCount}
                      defaultValue={formik.values.marriedBrotherCount }
                      disabled={noSiblings || formik.values.brotherCount == 0 || isAlreadySubmitted}
                      onChange={(selected) => {
                        setIsModified(true);
                        formik.setFieldValue("marriedBrotherCount", selected);
                      }}
                      options={[{ value: null, label: "Select", disabled: true },...marriedBroCountOptions]}
                    />

                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                <div className="col-span-1 lg:col-span-2 grid grid-cols-9 md:grid-cols-12 lg:grid-cols-12 mt-[2rem] ">
                  <div className="col-span-9 md:col-span-2 lg:col-span-3 flex items-center  ">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                    {trans('siblings.brothers_number')}
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="col-span-9 md:col-span-9 lg:col-span-8 pt-2 md:pt-0 lg:pl-2">
                      <div className="flex space-x-7 md:space-x-2 lg:space-x-3 gap-4 md:gap-0 lg:gap-0">
                        <SelectFamilyNumberInput
                          name="broIsdCode"
                          value={formik.values.broIsdCode}
                          onChange={(selected, ev) => {
                            setIsModified(true);
                            formik.setFieldValue("broIsdCode", selected);
                          }}
                          error={formik.errors.broIsdCode}
                          defaultValue={formik.values.broIsdCode}
                          options={[{ value: "", label: "Select", disabled: true },...props?.parentsIsdCodeDropDown]}
                          disabled={noSiblings || isAlreadySubmitted}
                          className={noSiblings ? "cursor-not-allowed" : ""}
                          menu="Numbercode"

                      />

                        <FamilyBasicEditTextView
                          inputID={"brotherNumber"}
                          inputName={"brotherNumber"}
                          value={formik.values.brotherNumber}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e);
                              setIsModified(true);
                            }
                          }}
                          error={formik.errors.brotherNumber}
                          inputType={"tel"}
                          disabled={noSiblings}
                          className={`${noSiblings ? "cursor-not-allowed" : "placeholder-color"}`}
                          placeholder= {trans('siblings.enter_brothers_mobile_number')}
                        />
                      </div>
                  </div>
                </div>
              </div>

              <div className="grid grid-cols-2 items-center gap-4 md:gap-4 lg:gap-0">
                <div>
                  <SelectFamilyInput
                    title={trans('view_profile.sisters')}
                    name="sistercount"
                    menu="aferRegister"
                    value={formik.values.sisterCount ? formik.values.sisterCount :"Select"}
                    defaultValue={formik.values.sisterCount}
                    error={formik.errors.sisterCount}
                    disabled={noSiblings || isAlreadySubmitted}
                    options={[{ value: null, label: "Select", disabled: true },...countOptions]}
                    onChange={(selected) =>{
                      setIsModified(true);
                      formik.setFieldValue("sisterCount", selected)
                    }}
                    placeholder={"select"}
                  />
                </div>
                <div>
                  <SelectFamilyInput
                    title= {trans('siblings.married')}
                    name="marriedSisterCount"
                    menu="aferRegister"
                    onChange={(selected) => {
                      setIsModified(true);
                      formik.setFieldValue("marriedSisterCount", selected);
                    }}
                    defaultValue={formik.values.marriedSisterCount}
                    value={formik.values.marriedSisterCount ? formik.values.marriedSisterCount :"Select"}
                    disabled={noSiblings || formik.values.sisterCount == 0 || isAlreadySubmitted} 
                    options={[{ value: null, label: "Select", disabled: true },...marriedSisterCountOptions]}
                    error={formik.errors.marriedSisterCount}
                  />

                </div>
              </div>
              <div className="grid grid-cols-1 md:grid-cols-1 lg:grid-cols-3">
                <div className="col-span-1 lg:col-span-2 grid grid-cols-9 md:grid-cols-12 lg:grid-cols-12 mt-[2rem] ">
                  <div className="col-span-9 md:col-span-2 lg:col-span-3 flex items-center">
                    <p className=" md:text-[12px] lg:text-[17px] flex text-[#1F1F1F]  font-semibold  ">
                       {trans('siblings.sisters_number')}
                    </p>
                  </div>
                  <div className="col-span-1 hidden md:flex lg:flex justify-center items-center">
                    <p className="font-bold">:</p>
                  </div>
                  <div className="col-span-9 md:col-span-9 lg:col-span-8 pt-2 md:pt-0 lg:pl-2">
                    <p className="">
                      <div className="flex space-x-7 md:space-x-2 lg:space-x-3 gap-4 md:gap-0 lg:gap-0">
                        <SelectFamilyNumberInput
                          name="sisIsdCode"
                          title="Sister's IsdCode"
                          value={formik.values.sisIsdCode}
                          onChange={(selected, ev) => {
                            setIsModified(true);
                            formik.setFieldValue("sisIsdCode", selected);
                          }}
                          error={formik.errors.sisIsdCode}
                          defaultValue={formik.values.sisIsdCode}
                          options={[{ value: "", label: "Select", disabled: true }, ...props?.parentsIsdCodeDropDown]}
                          disabled={noSiblings || isAlreadySubmitted}
                          className={noSiblings ? "cursor-not-allowed" : "placeholder-color"}
                          menu="Numbercode"

                        />

                        <FamilyBasicEditTextView
                          inputID={"sisterNumber"}
                          inputName={"sisterNumber"}
                          value={formik.values.sisterNumber}
                          onChange={(e) => {
                            if (validateNumber(e.target.value)) {
                              formik.handleChange(e);
                              setIsModified(true);
                            }
                          }}
                          error={formik.errors.sisterNumber}
                          inputType={"tel"}
                          disabled={noSiblings}
                          className={`${noSiblings ? "cursor-not-allowed" : "placeholder-color"}`}
                          placeholder= {trans('siblings.enter_sisters_mobile_number')}
                        />
                      </div>
                    </p>
                  </div>
                </div>
              </div>

              <SelectFamilyViewWithHeading
                title={trans('assets_owned_by_family.assets_owned_by_family')}
                inputID={"assetInfo"}
                inputName={"assetInfo"}
                value={formik?.values?.assetInfo}
                onChange={(selected, ev) => {
                  setIsModified(true);
                  formik.setFieldValue("assetInfo", selected);
                }}
                error={formik.errors.assetInfo}
                options={assetOptions}
                required={false}
                disabled={ isAlreadySubmitted}
                defaultValue={null}
                name={"asset"}
                className={noSiblings ? "cursor-not-allowed" : ""}
                menu="aferRegister"
              />
            </div>
          </div>
        </div>
        <div className="p-0 lg:px-[2rem] px-[0.9rem] bg-[#FFFFFF]">
        <div className="grid grid-cols-2 md:grid-cols-12 mt-[2rem]">
                <p className={`text-[#D63838] gap-3 font-bold text-[18px] md:text-[18px] lg:text-[20px] flex whitespace-nowrap  md:col-span-4 lg:col-span-2`} >
                {Male ? 'Groom' : Female ? 'Bride' : ''} lives in  <span className="font-bold text-[#D63838]">:</span>
                </p>
                
                <div className="md:col-span-4 flex items-center gap-4">
                  <div className="flex gap-2 items-center">
                  <input
                  id="groomLivesInIndia"
                  type="checkbox"
                  className="accent-gray-500 border border-[#F18181]"
                  value={formik.values.groomLivesInIndia}
                  checked={formik.values.groomLivesInIndia}
                  onChange={(e) => {
                    const checked = e.target.checked;
            
                    const areFieldsFilled =
                      formik.values.assetInfo?.length > 0 ||
                      formik.values.fathersName?.length > 0 ||
                      formik.values.fathersOccupation?.length > 0 ||
                      formik.values.fMobileNumber?.length > 0 ||
                      formik.values.isFather?.length > 0 ||
                      formik.values.mothersName?.length > 0 ||
                      formik.values.mothersOccupation?.length > 0 ||
                      (formik.values.motherMobileNumber?.length > 0 && !showMother) ||
                      formik.values.isMother?.length > 0 ||
                      formik.values.haveSiblings?.length > 0 ||
                      formik.values.brotherCount > 0 ||
                      formik.values.sisterCount > 0 ||
                      formik.values.marriedSisterCount > 0;
            
                   
                    if ( e.target.checked) {
                      // Handle when "India" is checked
                      setIsModified(true);
                      setworkCountryDropDown(workCountryDropDown?.map((item) => ({
                        value: item.id,
                        label: item.country,
                      })));
                      formik.setFieldValue("workCountry", "499");
                      formik.setFieldValue("workState", "");
                      formik.setFieldValue("workCity", "");
                      formik.setFieldValue("groomLivesInAbroad", false); // Uncheck "Abroad"
                      formik.setFieldValue("groomLivesInIndia", true);
                      apiCallState(499);
                      if (ProfileCountryId !== 499) {
                        formik.setFieldValue("isWorkLocationSame", false);
                      }
                    } else {
                      // Handle when "India" is unchecked
                      if (areFieldsFilled) {
                        setIsModified(true);
                        
                      } else {
                        setIsModified(false);
                      }
                      formik.setFieldValue("workCountry", "");
                      formik.setFieldValue("workState", "");
                      formik.setFieldValue("workCity", "");
                      formik.setFieldValue("isWorkLocationSame", false);
                      formik.setFieldValue("groomLivesInIndia", false);
                    }
                  }}
                    />
                    <p className={`text-[#000000] text-[12px] md:text-[14px] lg:text-[16px]`} >
                      India
                    </p>
                  </div>
                  <div className="flex gap-2 items-center">
                  <input
                   id="groomLivesInAbroad"
                    type="checkbox"
                    className={`accent-gray-500 border border-[#F18181]`}
                    name="groomLivesInAbroad"
                    value={formik.values.groomLivesInAbroad}
                    checked={formik.values.groomLivesInAbroad}
                   
                    onChange={(e) => {
                      const checked = e.target.checked;
                      formik.setFieldValue("groomLivesInIndia", false);
                      const areFieldsFilled =
                        formik.values.assetInfo?.length > 0 ||
                        formik.values.fathersName?.length > 0 ||
                        formik.values.fathersOccupation?.length > 0 ||
                        formik.values.fMobileNumber?.length > 0 ||
                        formik.values.isFather?.length > 0 ||
                        formik.values.mothersName?.length > 0 ||
                        formik.values.mothersOccupation?.length > 0 ||
                        (formik.values.motherMobileNumber?.length > 0 && !showMother) ||
                        formik.values.isMother?.length > 0 ||
                        formik.values.haveSiblings?.length > 0 ||
                        formik.values.brotherCount > 0 ||
                        formik.values.sisterCount > 0 ||
                        formik.values.marriedSisterCount > 0;
              
                     
                      if (checked) {
                        setIsModified(true);
                        // Handle when "Abroad" is checked
                        formik.setFieldValue("groomLivesInIndia", false); // Uncheck "India"
                        formik.setFieldValue("groomLivesInAbroad", true);
                        formik.setFieldValue("isWorkLocationSame", false);
                        formik.setFieldValue("workCountry", "");
                        formik.setFieldValue("workState", "");
                        formik.setFieldValue("workCity", "");
                        setworkCountryDropDown((country) =>
                          country?.filter((prev) => prev.value !== "499")
                        );
                      } else {
                        // Handle when "Abroad" is unchecked
                        if (areFieldsFilled) {
                          setIsModified(true);
                        } else {
                          setIsModified(false);
                        }
                        formik.setFieldValue("workCountry", "");
                        formik.setFieldValue("workState", "");
                        formik.setFieldValue("workCity", "");
                        formik.setFieldValue("isWorkLocationSame", false);
                        formik.setFieldValue("groomLivesInAbroad", false);
                      }
                    }}
              
                  />

                    <p className={`text-[#000000] text-[12px] md:text-[14px] lg:text-[16px]`} >
                      Abroad
                    </p>
                  </div>
                </div>
              </div>

            <div className="grid  grid-cols-12 items-center md:grid-cols-7 lg:grid-cols-9 lg:gap-7 space-x-7 lg:space-x-1 mt-[2rem]">
                <p className={`text-[#000000] gap-3 text-[18px] md:text-[18px] lg:text-[20px]  font-bold flex whitespace-nowrap col-span-5 md:col-span-3 lg:col-span-4`} >
                  Place of work <span className="font-bold">:</span>
                </p>
                
                <div className="col-span-6  md:col-span-4 lg:col-span-4 flex items-center">
                  <div className='flex gap-2 items-center'>
                  <input
                    type="checkbox"
                    name="isWorkLocationSame"
                    className={`accent-gray-500 border ${(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || 
                      (ProfileCountry== "India" && formik.values.groomLivesInAbroad) ||(ProfileCountry != "India" && formik.values.groomLivesInIndia)?"cursor-not-allowed":"cusror-defalut"} border-[#F18181]`}
                    checked={formik.values.isWorkLocationSame}
                    value={formik.values.isWorkLocationSame}
                    disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || 
                      (ProfileCountry== "India" && formik.values.groomLivesInAbroad) ||(ProfileCountry != "India" && formik.values.groomLivesInIndia)  }
                      onChange={(e) => {
                        formik.setFieldValue("isWorkLocationSame", e.target.checked);
                        if(e.target.checked){
                          if(ProfileCountry== "India"){
                            formik.setFieldValue("workCountry", ProfileCountryId);
                          formik.setFieldValue("workState", ProfileStateId);
                          formik.setFieldValue("workCity", ProfileCityId);
                            apiCallState(formik.values.workCountry);
                            apiCallCity(ProfileStateId)
                          }else{

                          
                          formik.setFieldValue("workCountry", ProfileCountryId);
                          formik.setFieldValue("workState", ProfileStateId);
                          formik.setFieldValue("workCity", ProfileCityId);
                            apiCallState(ProfileCountryId);
                            apiCallCity(ProfileStateId)
                          }
                        }else if(ProfileCountry== "India"){
                          formik.setFieldValue("workCountry", ProfileCountryId);
                          formik.setFieldValue("workState", " ");
                          formik.setFieldValue("workCity", " ");
                        }
                        else {
                          formik.setFieldValue("workCountry", "");
                          formik.setFieldValue("workState", " ");
                          formik.setFieldValue("workCity", " ");
                        }
                    }}
                                    />
                      <p className={`text-[#000000] text-[12px] md:whitespace-nowrap ${(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || 
                      (ProfileCountry== "India" && formik.values.groomLivesInAbroad) ||(ProfileCountry != "India" && formik.values.groomLivesInIndia)?"cursor-not-allowed":"cusror-defalut"} md:text-[14px] lg:text-[16px]`} >
                        Same location as previously mentioned.
                      </p>
                  </div>
                </div>
            </div>

            <SelectFamilyInput
              title="Country"
              name="workCountry"
              options={workCountryDropDown}
              // onChange={OnChangeOption}  // Pass both name and value
              onChange={(value ,name) => OnChangeOption('workCountry', value ,name)}
              // error={formik.errors.country}
              value={formik.values.workCountry ? formik.values.workCountry:"Select"}
              disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.groomLivesInIndia)  || (formik.values.isWorkLocationSame) || isAlreadySubmitted}
             
              menu="aferRegisterPlace"
            />
              
            
            <SelectFamilyInput
              name="workState"
              title="State"
              options={workStateDropDownOption}
              onChange={(value ,name) => OnChangeOption('workState', value ,name)}  // Pass both name and value
              // error={formik.errors.state}
              value={formik.values.workState ? formik.values.workState:"Select"}
              disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.isWorkLocationSame) || isAlreadySubmitted}
             menu="aferRegisterPlace"
            />
            
            <SelectFamilyInput
              name="workCity"
             title="City"
              options={workCityDropDownOption}
              onChange={(value ,name) => OnChangeOption('workCity', value ,name)}  // Pass both name and value
              // error={formik.errors.city}
              value={formik.values.workCity ? formik.values.workCity : "Select"}
              disabled={(formik.values.groomLivesInIndia == "" && formik.values.groomLivesInAbroad == "") || (formik.values.isWorkLocationSame) || isAlreadySubmitted}
              menu="aferRegisterPlace"
            />
        </div>
        <div className="flex  mt-7 items-center justify-around  py-4">
                  <div></div>
                  <div className="">
                    <button
                      type="submit"
                      className={`text-[14px]  text-white px-4 lg:px-[2.5rem] py-2.5 rounded-md ${(  !isModified|| isAlreadySubmitted )   ? "bg-[#DA3535] opacity-50 cursor-not-allowed" : "bg-[#DA3535] cursor-pointer"}`}
                      disabled={!isModified || isAlreadySubmitted }
                    >
                      {formik.isSubmitting ?<div className="flex"><img src={LoaderGif} className="brightness-0 invert-[1] w-5 h-5" alt=""/><span>{trans('upload_photos.submitting')}</span></div> : trans('registration.submit__continue')}
                    </button>
                  </div>
                  <div className="pl-[6rem] md:pl-[12rem]">
                    {(isCommunitySite || isAlreadySubmitted) ? null : (
                      <div className="flex justify-end ">
                        <button
                          onClick={() => {handleSkip();}}
                          type="button"
                          className="cursor-pointer pt-2 text-[#C1C1C1] text-[13px] underline"
                        >
                          {trans('kalyan_rewards_popup.skip')}
                        </button>
                      </div>
                    )}
                  </div>
        </div>
        </fieldset>
      </form>
    </>

  );
};

export default AboutFamily;
