import React, { useEffect, useState } from 'react';
import 'react-loading-skeleton/dist/skeleton.css'
import onlineicon from './../assets/Images/online icon.png'
import * as Images from "../assets/images"
import { Modal, Tooltip } from 'antd';
import MatchPopup from './MatchPopup';
import ProcessImage from '../Common/ProcessImage';
import { CommonPopup } from '../../CommonPopup';
import { useTranslation } from 'react-i18next';
import OverflowToolTip from '../../utils/OverflowTooltip';
import { useSelector } from 'react-redux';

export default function MatchItem({ data,welcomPage, pp,profileStatus,setshowWelcomPage, contact, contactInfo, time, active, work, onClick, searchTerm, adminSelected, profileId }) {


  const UnreadBadge = ({ count }) => {
    
      const backgroundColor = "bg-[#FDC300]" // Yellow background for unread messages
  
  
     
      
    return (
      // <span className='inline-flex items-center justify-center w-5 h-5 test-xs md:w-5 md:h-5 lg:w-[19px] lg:h-[19px] md:text-[.7rem] lg:text-[10px] font-semibold text-white bg-[#FDC300] rounded-full'>
      //   {count}
      // </span>
      <span className={`inline-flex items-center justify-center test-xs  pt-0.5 w-[19px] h-[19px] text-[10px] font-semibold  ${backgroundColor } text-white  rounded-full`}>
        {count}
      </span>
    );
  };

  const [isModalOpen, setIsModalOpen] = useState(false);

  const [showProfileHiddenPopup, setShowProfileHiddenPopup] = useState(false);
  const profileDetails = data;
 console.log(welcomPage,"welcomepageb");
  function getHighlightedText(text, highlight) {
    // Split text on highlight term, include term itself into parts, ignore case
    const parts = text.split(new RegExp(`(${highlight})`, 'gi'));
    return <span>{parts.map(part => part.toLowerCase() === highlight.toLowerCase() ? <span className="font-bold">{part}</span> : part)}</span>;
  }

  const { t:trans } = useTranslation();

  const conversationlist = useSelector(state => state?.Chats?.unReadCustomerMsgCount)

  console.log(contact,contactInfo,"conversationlistn");
  

  const profilePic = pp?.includes("femaleavatar") || pp?.includes("maleavatar") ? pp : pp

  const isAvatar = pp?.includes("femaleavatar") || pp?.includes("maleavatar") ? true :  false

  const handleImageClick = (e) => {
    e.stopPropagation()
    setIsModalOpen(true);
  };

  const handleClick = () => {
    setShowTooltip(false);
    if (profileStatus === "hidden" || profileStatus==="inactive"||
     profileStatus==="suspension"
    ||profileStatus==="delete") {
      // welcomPage(true);
      // setshowWelcomPage(true)
      setShowProfileHiddenPopup(true);
     
    } else {
      onClick();
      // 
      let storedProfileIds = JSON.parse(sessionStorage.getItem('viewedProfileIds')) || [];

      // Check if the clicked profile ID is already stored
      if (!storedProfileIds.includes(profileId)) {
        // Add the new profile ID to the list
        storedProfileIds.push(profileId);
  
        // Update local storage with the new array
        sessionStorage.setItem('viewedProfileIds', JSON.stringify(storedProfileIds));
      }
    }
  };


  const highlightSearchTerm = () => {
    if (!searchTerm) {
      return contact;
    }

    return getHighlightedText(contact, searchTerm)

    // const regex = new RegExp(`(${searchTerm})`, 'gi');
    // return contact.split(regex).map((part, index) =>
    //   regex.test(part) ? <span key={index} className="font-bold">{part}</span> : part
    // );
  };

  const formatLastSeen = (lastSeen) => {
    const now = new Date();
    const lastSeenDate = new Date(lastSeen);

    // Check if the user was seen today
    if (now.toDateString() === lastSeenDate.toDateString()) {
      // Format time as HH:mm AM/PM
      return lastSeenDate.toLocaleTimeString([], { hour: 'numeric', hourCycle: "h12", minute: '2-digit' });
    }

    // Check if the user was seen yesterday
    const yesterday = new Date(now);
    yesterday.setDate(now.getDate() - 1);
    if (yesterday.toDateString() === lastSeenDate.toDateString()) {
      return 'Yesterday';
    }

    // Format as DD/MM/YY
    const day = lastSeenDate.getDate();
    const month = lastSeenDate.getMonth() + 1;
    const year = lastSeenDate.getFullYear() % 100;
    return `${day}/${month}/${year}`;
  };
  // const viewPhoneData = profileData?.viewPhone;



  const handleError = (event) => {
    // Handle the image loading error
    event.target.src = Images.DefaultAvatar; // Set the source to the default image
  };




  const [showTooltip, setShowTooltip] = useState(false);

 

  useEffect(() => {
    let loginTime = localStorage.getItem("loginTimestamp");
    let tooltipHidden = localStorage.getItem("tooltipHidden");

    console.log("Login Time from Storage:", loginTime);
    console.log("Tooltip Hidden Status:", tooltipHidden);
    console.log("Unread Messages Count:", conversationlist?.unread_message_count);

    if (!loginTime) {
      // Store login time when user logs in
      loginTime = Date.now();
      localStorage.setItem("loginTimestamp", loginTime);
      console.log("Setting Login Timestamp:", loginTime);
    } else {
      loginTime = parseInt(loginTime, 10);
    }

    const elapsedTime = Date.now() - loginTime;
    console.log("Elapsed Time Since Login:", elapsedTime / 1000, "seconds");

    if (tooltipHidden === "true") {
      console.log("Tooltip is already hidden. Not displaying.");
      setShowTooltip(false);
    } else if (conversationlist?.unread_message_count > 0) {
      if (elapsedTime < 2 * 60 * 1000) {
        console.log("Showing tooltip.");
        setShowTooltip(true);

        // Hide tooltip after the remaining time
        const timer = setTimeout(() => {
          console.log("Hiding tooltip after timeout.");
          setShowTooltip(false);
          localStorage.setItem("tooltipHidden", "true"); // Mark as hidden permanently
        }, 2 * 60 * 1000 - elapsedTime);

        return () => clearTimeout(timer);
      } else {
        console.log("More than 2 minutes elapsed. Hiding tooltip permanently.");
        setShowTooltip(false);
        localStorage.setItem("tooltipHidden", "true");
      }
    } else {
      console.log("No unread messages. Tooltip will not show.");
      setShowTooltip(false);
    }
  }, [conversationlist?.unread_message_count]);


  useEffect(()=>{
    localStorage.setItem("clicktoprequest", "false");
  },[])
  
  return (
    <>
      <Modal
        closable={false}
        footer={null}
        className='match-popup'
        onCancel={() => setIsModalOpen(false)} open={isModalOpen} centered>
        <MatchPopup profileId={profileId} close={() => setIsModalOpen(false)} onViewProfile={onClick} />
      </Modal >

      {showProfileHiddenPopup && (
  <CommonPopup
    menu="liteprofile"
    title={trans('my_profile.profile_status')}
    close={() => setShowProfileHiddenPopup(false)}
  >
    <p>{profileStatus === "hidden" ||profileStatus==="inactive"
            ? `Profile is ${profileStatus}` : profileStatus === "delete" ? "Profile is deleted" :
              profileStatus === "suspension" ? "Profile is suspended" : `Your Profile is ${profileStatus}`}</p>
  </CommonPopup>
)}



  
{showTooltip && conversationlist?.unread_message_count > 0 && contact==trans('matches.kalyan_matrimony') &&
                           <div className="relative  md:top-0 top-[0.5rem]  lg:top-[0.8rem] z-10">
                             <div className="absolute bg-[#ffffff] w-auto max-w-fit z-10 rounded-b-lg border border-[#000000] shadow-lg md:left-[9px] lg:left-[12px] h-[1.5rem] left-[22px] bottom-[-1rem] md:bottom-[-1.3rem] lg:bottom-[-0.7rem] lg:h-[1.5rem] md:h-[1.3rem] flex items-center justify-center ml-auto mr-auto">
                                  <span className="text-[#000000] px-[0.5rem] font-bold whitespace-nowrap italic text-[11px] md:text-[9px] lg:text-[9px] xl:text-[10px]" style={{ fontStyle: "italic"}}>Missed adding some profile details? Click here to add!</span>
                                  <img src={Images.whitedownarrow} className=" w-4 h-4 absolute bottom-[-10px] md:bottom-0 lg:top-[21px] top-[21px] md:top-[18px]  left-[1rem] z-[2]" alt=""></img>
                                </div>
                              </div>}

      <div onClick={() => handleClick()} id={`match-item-${profileId}`} className={`px-4 py-1  md:p-2 lg:px-3 xl:p-2 lg:h-[4.6rem] h-[5.3rem] xl:h-[5rem] ${contact === "kalyan matrimony" ? "px-4 py-1  md:p-2 lg:px-3 xl:p-2 lg:h-[4.6rem] md:mb-[-1rem] lg:mb-0 h-[5.3rem] xl:h-[5rem]" : ""}  ${adminSelected || data?.FamilySelect ? "" : "overflow-hidden"} hover:bg-[#E3E3E3] cursor-pointer flex items-center  ${active ? "bg-gray-200" : "bg-[#F7F8FA]"}`}>
        <div className="w-full h-20 lg:h-[4rem] xl:h-[4.5rem] flex ">
          <div className='w-[21%] h-full relative flex items-center xl:w-[19%] 2xl:w-[15%] 3xl:w-[13%] lg:w-[20%] md:w-[17%]'>
            {data?.FamilySelect ?
              // <div className="flex items-center justify-center">
              //   <div className="relative">
              //     <div className="absolute w-[2rem] top-[-2.5rem] md:top-[-2rem] lg:top-[-2.2rem] xl:top-[-2.4rem] left-[1.4rem] md:left-[1.2rem] lg:left-[1rem] xl:left-[1.4rem] z-50 border-l-[15px] border-l-transparent border-t-[17px] border-t-[#FDC300] border-r-[22px] border-r-transparent">
              //     </div>
              //     <p className="text-[#000000] text-[8px] absolute top-[-2.9rem] md:top-[-2.3rem] lg:top-[-2.4rem] xl:top-[-2.6rem] left-[1.4rem] md:left-[1.1rem] lg:left-[1rem] xl:left-[1.4rem] z-50 font-segeo rounded-lg font-bold bg-[#FDC300] px-[8px] pt-2 md:pt-[4.2px] whitespace-nowrap">
              //       {data?.FamilySelect}
              //     </p>
              //   </div>
              // </div>
              <div className="flex items-center justify-center">
                <div className="relative">
                  <div className="absolute w-[2rem] top-[-2.5rem] left-[1rem] z-[1] border-l-[15px] border-l-transparent border-t-[17px] border-t-[#FDC300] border-r-[22px] border-r-transparent">
                  </div>
                  <p className="text-black text-[8px] absolute top-[-2.9rem] left-[1rem] z-[1] font-segeo rounded-lg font-bold bg-[#FDC300] px-[8px] py-[4px] whitespace-nowrap">
                  {data?.FamilySelect}
                  </p>
                </div>
              </div>
              :
              adminSelected ?
              <div className="flex items-center justify-center">
                <div className="relative">
                  <div className="absolute w-[2rem] top-[-2.5rem] left-[1rem] z-[1] border-l-[15px] border-l-transparent border-t-[17px] border-t-[#D10A11] border-r-[22px] border-r-transparent">
                  </div>
                  <p className="text-white text-[8px] absolute top-[-2.9rem] left-[1rem] z-[1] font-segeo rounded-lg font-bold bg-[#D10A11] px-[8px] py-[4px] whitespace-nowrap">
                    {trans('matches.profile_selected_by_relationsh')}
                  </p>
                </div>
              </div>
              :
              <></>
            }

            <ProcessImage
              blurPhoto={data?.profileblurPhoto}
              alreadyContacted={data?.profilealreadyContected}
              passwordProtected={data?.profilefromPasswordProtected}
              onClick={null}
              onError={handleError}
              isAvatarPic={data?.isAvatarPic}
              className={`hidden md:block rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px] ${isAvatar ? "border border-[#8292A8]" : ""}`}
              src={profilePic}
              alt=""
            />

            <ProcessImage
              blurPhoto={data?.profileblurPhoto}
              alreadyContacted={data?.profilealreadyContected}
              passwordProtected={data?.profilefromPasswordProtected}
              onClick={handleImageClick}
              onError={handleError}
              isAvatarPic={data?.isAvatarPic}
              className={`md:hidden rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px] ${isAvatar ? "border border-[#8292A8]" : ""}`}
              src={profilePic}
              alt=""
            />


                          
            {/* <img
              onError={handleError}
              className={`${!profileDetails?.profiledetails?.isAvatarPic?
              profileDetails?.blurPhoto == "Y" ? 
            profileDetails?.alreadyContected == "Y" ?
            "a" 
            :profileDetails?.alreadyContected == "N"  ? 
            "blur-[2px]" 
            :"blur-[2px]" 
            : profileDetails?.alreadyContected == "N" 
            && profileDetails?.myphoto == "" || profileDetails?.myphoto == "N"
            
            ? "b"
          
            : profileDetails?.alreadyContected == "N" 
            && profileDetails?.myphoto == "Y" 
            
            ?"c"
            :
            profileDetails?.myphoto == "Y" && 
            profileDetails?.status == "open" ? // view all photo
            "d"
            :profileDetails?.myphoto == "" || profileDetails?.myphoto == "N"?" "                                   
            :"e"
            :"f"
            
              } md:hidden rounded-full lg:h-[43px] lg:w-[43px] xl:h-[52px] xl:w-[52px] md:h-[43px] object-cover w-[60px] h-[58px] md:w-[43px]`} src={profilePic} alt={contact} onClick={handleImageClick} /> */}

          </div>
          <div className="w-[60%] md:pl-2 lg:pl-0 h-full grow flex flex-col justify-center" >
            <h3 className="font-Poppins text-[#4C5B71] md:text-[13px] xl:text-[16px] lg:text-[13px] flex gap-2 items-center">{highlightSearchTerm()}
              {/* {contact == "Senthil" && 
                        <span>
                            <img className="w-4 h-4" src={VerifiedIcon} alt="Verified" /></span>
                            } */}
            </h3>
            <p className="font-Poppins-Regular xl:w-[12rem] 2xl:w-[16rem] lg:w-[9rem] md:w-[8rem] w-[11rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">{contactInfo}</p>
            <p className="font-Poppins-Regular xl:w-[12rem] 2xl:w-[16rem] lg:w-[9rem] md:w-[8rem] w-[11rem] text-[#8292A8] lg:ml-0 text-[12px] md:text-[11px] xl:text-[13px] lg:text-[10px] overflow-hidden whitespace-nowrap overflow-ellipsis">{work}</p>
          </div>
          {contact==trans('matches.kalyan_matrimony') ? '' : <div className="w-[15%] h-full rounded-full self-start flex justify-end items-start">
            {profileStatus === "inactive" ? 
              <p className="font-Poppins text-[#8292A8] text-[9px] lg:text-[8px] xl:text-[9px] lg:pt-3 xl:pt-3 pt-4">Inactive</p>
              :
              time && time === "Online Now" ?
                <>
                  <p className="font-Poppins text-[#039511] lg:text-[8px] text-[9px] xl:text-[9px] lg:pt-3 xl:pt-4 flex pt-5 md:pr-2">
                    <img className='w-3 h-3' src={onlineicon} alt='' />
                    <span className='flex items-center justify-center pl-1'>{trans('view_profile.online')}</span>
                  </p>
                </>
              :
              time ? <p className="font-Poppins text-[#8292A8] text-[9px] lg:text-[8px] xl:text-[9px] lg:pt-3 xl:pt-3 pt-4">{formatLastSeen(time)}</p> : null
            }
          </div>}
          {conversationlist?.unread_message_count > 0 && contact==trans('matches.kalyan_matrimony') &&
             <div className="w-[15%] h-full rounded-full self-start flex justify-center items-center">
               <div className={`${ 'mt-2 ml-[1.5rem] flex justify-center '}`}>
                      <UnreadBadge count={conversationlist?.unread_message_count} 
                      unreadMsgcustomer ={conversationlist?.unread_message_count}/> 
                    </div>
           </div>
          }
          <div className="relative">
            <div className="absolute right-0 bottom-[11px] md:bottom-[15px] lg:bottom-[6px]">
            {contact == "Kalyan Matrimony" ? <></> :
            data?.alreadyviewed == "yes" && 
            <div className="flex">
              <OverflowToolTip as="p" className="font-Poppins text-[#000000] text-[9px] md:text-[7px] lg:text-[8px] xl:text-[9px] border border-[#929191] rounded-full px-2 truncate max-w-[6rem]">{trans('matches.already_viewed')}</OverflowToolTip>
            </div>
            }
            </div>
          </div>

        </div>

      </div >
    </>
  )
}




export function SkeletonMatchItem() {
  return (
    <div className="w-full p-4 rounded-md md:p-2 lg:p-2">
      <div className="flex flex-row items-center h-full animate-pulse">
        <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full mr-4 md:mr-2 lg:mr-4 ">
        </div>
        <div className="flex flex-col space-y-2 grow">
          <div className="w-[30%] bg-gray-300 h-3 rounded-md ">
          </div>
          <div className="w-full h-3 bg-gray-300 rounded-md ">
          </div>
          <div className="w-full h-3 bg-gray-300 rounded-md ">
          </div>
        </div>
      </div>
    </div>
  )

}

export function SenderChatItemSkeleton() {
  return (
    <div className='w-1/2 p-4 rounded-md md:p-0 md:px-1 lg:px-1'>
      <div className='flex flex-row items-start h-full gap-4 animate-pulse'>
     
        <div className='flex flex-col w-full'>
          <div className='relative self-start w-4 bg-gray-300 box sb1 top-1 rounded-bl-md'></div>

          <div className='w-full bg-gray-300 h-[2rem] rounded-md'></div>
          
        </div>
        <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-6 lg:h-6 rounded-full mr-4 md:mr-2 lg:mr-0 ">
        </div>
        
      </div>
    </div>
  );
}

export function ReceiverChatItemSkeleton() {
  return (
    <div className='w-1/2 p-4 rounded-md md:p-0 md:px-1 lg:px-1'>
      <div className='flex flex-row items-start h-full animate-pulse'>
      <div className="bg-gray-300 w-[3.5rem] h-[3.5rem] md:w-8 md:h-8 lg:w-10 lg:h-10 rounded-full mr-4 md:mr-2 lg:mr-4 ">
        </div>
        <div className='flex flex-col w-full space-y-3'>
        <div className='relative self-start w-4 h-4 bg-gray-300 box sb2 top-4 rounded-bl-md'></div>
          <div className='w-full bg-gray-300 h-[5rem] rounded-md'></div>

        </div>
      </div>
    </div>
  );
}